import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";
import { TermsDocs } from "./terms";

const ScMain = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #020203;

  .post-full-content {
    background-color: transparent;
  }
`;

const TCPage = () => {
  React.useEffect(() => {
    (function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
    })(document, "script", "termly-jssdk");
  }, []);

  return (
    <Layout>
      <ScMain>
        <Container>
          <article className="post-full post no-image page no-image">
            <h1>Terms & Conditions</h1>
            <div
              name="termly-embed"
              data-id="9a5d165f-f047-42bc-b652-f0bff929248e"
              data-type="iframe"
            ></div>
          </article>
        </Container>
      </ScMain>
    </Layout>
  );
};

export default TCPage;

export const Head: HeadFC = () => <title>Disclaimer</title>;
